import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageHeader from './page-header.js';
import toggleMenu from '../actions/toggle-menu.js';
import openCookiesInfo from '../actions/open-cookies-info.js';
import closeCookiesInfo from '../actions/close-cookies-info.js';

const PageHeaderContainer = withRouter(connect(
  (state) => ({
    isMenuOpen: state.header.isMenuOpen,
    isCookiesInfoOpen: state.header.isCookiesInfoOpen
  }),
  (dispatch) => ({
    toggleMenu: () => dispatch(toggleMenu()),
    openCookiesInfo: () => dispatch(openCookiesInfo()),
    closeCookiesInfo: () => dispatch(closeCookiesInfo())
  })
)(PageHeader));

export default PageHeaderContainer;
