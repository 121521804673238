import React from 'react';
import Component from '../../_shared/components/component.js';
import MainContainer from './main-container.js';
import PageHeaderContainer from '../../_shared/components/page-header-container.js';
import PageFooterContainer from '../../_shared/components/page-footer-container.js';

export default class Root extends Component {
  render() {
    return pug`
      div(id='root')
        PageHeaderContainer
        MainContainer
        PageFooterContainer
    `;
  }
}
