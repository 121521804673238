import seeBrowserWarning from './see-browser-warning.js';

export default function () {
  return (dispatch, getState) => {
    const state = getState();
    if(!state.cookies.browserWarningSeen) {
      dispatch(seeBrowserWarning());
      dispatch({
        type: 'OPEN BROWSER WARNING'
      });
    } 
  };
}
