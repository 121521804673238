import React from 'react';
import Component from './component.js';
import Modernizr from 'modernizr';
import globalObject from 'global-object';

export default class Scrollbar extends Component {
  constructor(props) {
    super(...arguments);
    this.isScrolling = false;
    this.mouseDownListener = this.mouseDownListener.bind(this);
    this.mouseUpListener = this.mouseUpListener.bind(this);
    this.mouseMoveListener = this.mouseMoveListener.bind(this);
    this.keyDownListener = this.keyDownListener.bind(this);
    this.wheelListener = this.wheelListener.bind(this);
    this.isScrollGesture = this.isScrollGesture.bind(this);
    this.touchListener = this.touchListener.bind(this);
    this.setScrollbar = this.setScrollbar.bind(this);
    this.isPrimaryButtonDown = this.isPrimaryButtonDown.bind(this);
    this.touches = [];
    this.intendedPercentage = 0;
  }

  style() {
    return {
      top: `${this.props.percentage * 90 / 100}%`
    };
  }

  changeIntendedPercentage(newIntendedPercentage) {
    const boundedNewIntendedPercentage = Math.min(100, Math.max(0, newIntendedPercentage));
    if(this.intendedPercentage !== boundedNewIntendedPercentage) {
      this.intendedPercentage = boundedNewIntendedPercentage;
      this.props.onScrollbarMove(this.intendedPercentage);
    }
  }

  isPrimaryButtonDown(event) {
    if(typeof event.buttons !== 'undefined') {
      return event.buttons & 1 === 1;
    }
    switch(event.type) {
      case 'mousemove':
        return this.isScrolling;
      case 'mousedown':
        return event.button === 0;
      case 'mouseup':
        return event.button === 0 ? false : this.isScrolling;
      default:
        return false;
    }
  }

  mouseDownListener(event) {
    if(this.isPrimaryButtonDown(event)) {
      this.isScrolling = true;
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    return true;
  }

  mouseUpListener(event) {
    if(!this.isScrolling) {
      return true;
    }
    if(!this.isPrimaryButtonDown(event)) {
      this.isScrolling = false;
      return true;
    }
    return true;
  }

  mouseMoveListener(event) {
    if(!this.isScrolling) {
      return true;
    }
    if(!this.isPrimaryButtonDown(event)) {
      this.isScrolling = false;
      return true;
    }
    event.stopPropagation();
    event.preventDefault();
    const parentRect = this.scrollbar.parentNode.getBoundingClientRect();
    const parentHeight = parentRect.bottom - parentRect.top;
    const restrictedMouseY = Math.max(parentRect.top, Math.min(parentRect.bottom, event.clientY));
    this.changeIntendedPercentage(100 * (restrictedMouseY-parentRect.top) / parentHeight);
    return false;
  }

  keyDownListener(event) {
    const keyPercentageStep = 1;
    const pagePercentageStep = keyPercentageStep * 10;
    if(!event.altKey && !event.ctrlKey && !event.shiftKey) {
      switch(event.key) {
        case 'ArrowDown':
          event.stopPropagation();
          event.preventDefault();
          this.changeIntendedPercentage(this.intendedPercentage + keyPercentageStep);
          return false;
        case 'ArrowUp':
          event.stopPropagation();
          event.preventDefault();
          this.changeIntendedPercentage(this.intendedPercentage - keyPercentageStep);
          return false;
        case 'PageDown':
          event.stopPropagation();
          event.preventDefault();
          this.changeIntendedPercentage(this.intendedPercentage + pagePercentageStep);
          return false;
        case 'PageUp':
          event.stopPropagation();
          event.preventDefault();
          this.changeIntendedPercentage(this.intendedPercentage - pagePercentageStep);
          return false;
        case 'Home':
          event.stopPropagation();
          event.preventDefault();
          this.changeIntendedPercentage(0);
          return false;
        case 'End':
          event.stopPropagation();
          event.preventDefault();
          this.changeIntendedPercentage(100);
          return false;
        case ' ':
          event.stopPropagation();
          event.preventDefault();
          this.changeIntendedPercentage(this.intendedPercentage + pagePercentageStep);
          return false;
      }
    }
    if(!event.altKey && !event.ctrlKey && event.shiftKey) {
      switch(event.key) {
        case ' ':
          event.stopPropagation();
          event.preventDefault();
          this.changeIntendedPercentage(this.intendedPercentage - pagePercentageStep);
          return false;
      }
    }
    return true;
  }

  wheelListener(event) {
    event.stopPropagation();
    event.preventDefault();
    const percentagesPerPixel = 0.01;
    const percentagesPerLine = 20 * percentagesPerPixel;
    const percentagesPerPage = 25 * percentagesPerLine;
    switch(event.deltaMode) {
      case 0:
        this.changeIntendedPercentage(this.intendedPercentage + event.deltaY * percentagesPerPixel);
        return false;
      case 1:
        this.changeIntendedPercentage(this.intendedPercentage + event.deltaY * percentagesPerLine);
        return false;
      case 2:
        this.changeIntendedPercentage(this.intendedPercentage + event.deltaY * percentagesPerPage);
        return false;
    }
    return false;
  }

  isScrollGesture(event) {
    return event.type === 'touchmove' &&
      this.touches.length === 1 &&
      event.touches.length === 1 &&
      event.changedTouches.length === 1;
  }

  touchListener(event) {
    const percentagesPerPixel = 0.02;
    if(this.isScrollGesture(event)) {
      event.preventDefault();
      event.stopPropagation();
      const previousTouch = this.touches.item(0);
      const currentTouch = event.touches.item(0);
      this.changeIntendedPercentage(this.intendedPercentage - (currentTouch.clientY - previousTouch.clientY) * percentagesPerPixel);
    }
    this.touches = event.touches;
  }

  componentDidMount() {
    if(globalObject.addEventListener) {
      let options;
      if(Modernizr.passiveeventlisteners) {
        options = {passive: false, capture: true};
      } else {
        options = true;
      }
      ['touchstart', 'touchmove', 'touchend', 'touchcancel'].forEach((eventName) => {
        this.scrollbar.parentNode.addEventListener(eventName, this.touchListener, options);
      });
      this.scrollbar.parentNode.addEventListener('wheel', this.wheelListener, options);
      globalObject.addEventListener('mouseup', this.mouseUpListener, true);
      globalObject.addEventListener('mousemove', this.mouseMoveListener, true);
      globalObject.addEventListener('keydown', this.keyDownListener, false);
    }
  }

  componentWillUnmount() {
    if(globalObject.removeEventListener) {
      let options;
      if(Modernizr.passiveeventlisteners) {
        options = {passive: false, capture: true};
      } else {
        options = true;
      }
      ['touchstart', 'touchmove', 'touchend', 'touchcancel'].forEach((eventName) => {
        this.scrollbar.parentNode.addEventListener(eventName, this.touchListener, options);
      });
      this.scrollbar.parentNode.removeEventListener('wheel', this.wheelListener, options);
      this.scrollbar.parentNode.removeEventListener('keydown', this.keyDownListener, false);
      globalObject.removeEventListener('mouseup', this.mouseUpListener, true);
      globalObject.removeEventListener('mousemove', this.mouseMoveListener, true);
    }
  }

  setScrollbar(scrollbar) {
    this.scrollbar = scrollbar;
  }

  render() {
    return pug`
      .scrollbar(
        ref=this.setScrollbar
        style=this.style()
        onMouseDown=this.mouseDownListener
      )
    `;
  }
}
