import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Main from './main.js';
import smoothScrollTo from '../../_shared/actions/smooth-scroll-to.js';
import seeScrollMe from '../../_shared/actions/see-scroll-me.js';
import openBrowserWarning from '../../_shared/actions/open-browser-warning.js';
import start3D from '../../_shared/actions/start-3d.js';

const MainContainer = withRouter(connect(
  (state) => ({
    scrollbarPercentage: state.scrollbar.actualPercentage,
    modernizrAvailable: state.scrollbar.modernizrAvailable,
    is3D: state.scrollbar.is3D,
    scrollMeSeen: state.cookies.scrollMeSeen
  }),
  (dispatch) => ({
    smoothScrollTo: (percentage) => {
      dispatch(seeScrollMe());
      dispatch(smoothScrollTo(percentage));
    },
    openBrowserWarning: () =>
      dispatch(openBrowserWarning()),
    start3D: () =>
      dispatch(start3D())
  })
)(Main));

export default MainContainer;
