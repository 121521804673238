import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageFooter from './page-footer.js';
import closeBrowserWarning from '../actions/close-browser-warning.js';
import openFooter from '../actions/open-footer.js';
import closeFooter from '../actions/close-footer.js';

const PageFooterContainer = withRouter(connect(
  (state) => ({
    isBrowserWarningOpen: state.footer.isBrowserWarningOpen,
    isFooterOpen: state.footer.isFooterOpen,
    year: state.footer.year
  }),
  (dispatch) => ({
    closeBrowserWarning: () =>
      dispatch(closeBrowserWarning()),
    openFooter: () =>
      dispatch(openFooter()),
    closeFooter: () =>
      dispatch(closeFooter())
  })
)(PageFooter));

export default PageFooterContainer;
