import moment from 'moment';

export default function (state, action) {
  const defaultCookieOptions = {
    path: '/',
    expires: moment().add(2, 'months').toDate(),
    domain: '.ideapiece.com',
    secure: false,
    httpOnly: false
  };

  if (typeof state === 'undefined') {
    return {
      universalCookies: null,
      browserWarningSeen: false,
      scrollMeSeen: false
    };
  }

  switch(action.type) {
    case 'INITIALIZE COOKIES':
      return Object.assign({}, state, {
        universalCookies: action.universalCookies,
        browserWarningSeen: action.universalCookies.get('browserWarningSeen') === 'true',
        scrollMeSeen: action.universalCookies.get('scrollMeSeen') === 'true'
      });
    case 'UNINITIALIZE COOKIES':
      return Object.assign({}, state, {
        universalCookies: null
      });
    case 'SEE BROWSER WARNING':
      state.universalCookies.set('browserWarningSeen', 'true', defaultCookieOptions);
      return Object.assign({}, state, {
        browserWarningSeen: true
      });
    case 'SEE SCROLL ME':
      if (state.scrollMeSeen) {
        return state;
      }
      state.universalCookies.set('scrollMeSeen', 'true', defaultCookieOptions);
      return Object.assign({}, state, {
        scrollMeSeen: true
      });
  }
  
  return state;
}
