import React from 'react';
import Component from './component.js';
import { Modal, Button, Icon } from 'semantic-ui-react';
import ent from '../helpers/ent.js';

const ModalContent = Modal.Content;
const ModalActions = Modal.Actions;

export default class PageFooter extends Component {
  constructor(props) {
    super(...arguments);
    this.getFooterClasses = this.getFooterClasses.bind(this);
    this.yearRange = this.yearRange.bind(this);
  }

  getFooterClasses() {
    if(this.props.isAlwaysOpen || this.props.isFooterOpen) {
      return ['open'];
    } else {
      return [];
    }
  }

  yearRange() {
    const startYear = 2017;
    const currentYear = this.props.year;
    if(startYear === currentYear) {
      return `${startYear}`;
    } else {
      return `${startYear}–${currentYear}`;
    }
  }

  render() {
    return pug`
      footer(className=this.getFooterClasses())
        p
          small
            if !this.props.isAlwaysOpen
              if this.props.isFooterOpen
                a(className='footer-open-close' href='#' onClick=this.props.closeFooter)
                  Icon(name='arrow circle down' fitted=true)
                  | #{ent.nbsp}Hide#{ent.nbsp}|#{ent.nbsp}
              else
                a(className='footer-open-close' href='#' onClick=this.props.openFooter)
                  Icon(name='arrow circle up' fitted=true)
                  | #{ent.nbsp}Show all#{ent.nbsp}|#{ent.nbsp}
            | Copyright#{ent.nbsp}©#{ent.nbsp}${this.yearRange()} IdeaPiece#{ent.nbsp}Ltd. All rights reserved. | IdeaPiece#{ent.nbsp}Ltd is a#{ent.nbsp}company registered in#{ent.nbsp}England and Wales under company number 10681999.
            |  Registered office: 20#{ent.nbsp}Chinnock Brook, Didcot, Oxfordshire, United Kingdom, OX11#{ent.nbsp}7XU. VAT#{ent.nbsp}number: 265#{ent.nbsp}1808#{ent.nbsp}95.
        Modal(
          open=this.props.isBrowserWarningOpen
          closeOnDimmerClick=false
          closeOnDocumentClick=false
        )
          ModalContent
            p
              | Unfortunately, your browser does not support all the features necessary to properly display
              |  this website. A basic version of the website will be displayed instead. If you prefer the improved
              |  experience, please, use a version or a browser which supports 3D. Most modern desktop browsers support it.
              |  On the mobile Chrome and Safari support all features.
          ModalActions
            Button(primary=true icon='close' content='Close' onClick=this.props.closeBrowserWarning)
    `;
  }
}
