import { createBrowserHistory } from 'history';
import universalCookies from 'universal-cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server.js';
import { StaticRouter, Route, Link } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import buildStore from './store.js';
import initializeCookies from '../_shared/actions/initialize-cookies.js';
import uninitializeCookies from '../_shared/actions/uninitialize-cookies.js';
import Root from './components/root.js';
import './stylesheets/index.less';

export default class Renderer {
  constructor(uri = undefined) {
    if(typeof document !== 'undefined') {
      this.browser = true;
      this.history = createBrowserHistory();
      this.rootContainer = document.getElementById('root-container');
      this.store = buildStore(window.__PRELOADED_STATE__, this.history);
    } else {
      this.browser = false;
      this.context = {};
      this.uri = uri;
      this.store = buildStore();
    }
  }

  initializeCookies(cookies = undefined) {
    this.store.dispatch(initializeCookies(new universalCookies(cookies)));
  }

  uninitializeCookies() {
    this.store.dispatch(uninitializeCookies());
  }

  render() {
    if(this.browser) {
      ReactDOM.render(pug`
        Provider(store = this.store)
          ConnectedRouter(history = this.history)
            Root
      `, this.rootContainer);
    } else {
      return ReactDOMServer.renderToString(pug`
        Provider(store = this.store)
          StaticRouter(location = this.uri, context = this.context)
            Root
      `);
    }
  }
}

if(typeof document !== 'undefined') {
  const renderer = new Renderer();
  renderer.initializeCookies();
  renderer.render();
}
