import React from 'react';

export default class Component extends React.Component {
  bind(...methodNames) {
    for(const methodName of methodNames) {
      if(typeof this[methodName] === 'undefined') {
        throw `ERROR: Cannot bind ${methodName}`;
      }
      this[methodName] = this[methodName].bind(this);
    }
  }
}
