import React from 'react';
import Component from '../../_shared/components/component.js';
import Modernizr from 'modernizr';
import Bowser from 'bowser';
import Stand from './stand.js';
import ent from '../../_shared/helpers/ent.js';
import Scrollbar from '../../_shared/components/scrollbar.js';
import ScrollMe from '../../_shared/components/scroll-me.js';

export default class Main extends Component {
  constructor(props) {
    super(...arguments);
    this.bind(
      'setScrollPane',
      'setMain',
      'scrollToPercentage',
      'mainClasses',
      'getScrollListener',
      'initialize3D',
      'browserSupportsAllFeatures'
    );
  }

  browserSupportsAllFeatures() {
    if(typeof navigator == 'undefined') {
      return true;
    }

    const browser = Bowser.getParser(window.navigator.userAgent);

    if(!Modernizr.csstransforms3d) {
      return false;
    }
    if(!Modernizr.preserve3d) {
      return false;
    }
    if(browser.isBrowser('Internet Explorer')) {
      return false;
    }
    if(browser.isBrowser('Microsoft Edge')) {
      return false;
    }
    if(!browser.isPlatform('desktop') && !browser.isBrowser('Chrome') && !browser.isBrowser('Safari')) {
      return false;
    }
    if(!browser.satisfies({
      chrome: '>= 49.0',
      firefox: '>= 43.0',
      opera: '>= 36.0',
      safari: '>= 10.0'
    })) {
      return false;
    }
    return true;
  }

  initialize3D() {
    if(this.browserSupportsAllFeatures()) {
      this.props.start3D();
    } else {
      this.props.openBrowserWarning();
    }
  }

  setScrollPane(scrollPane) {
    this.scrollPane = scrollPane;
  }

  scrollToPercentage() {
    this.scrollPane.scrollTop = (this.scrollPane.scrollHeight - this.scrollPane.clientHeight) * this.props.scrollbarPercentage / 100;
  }

  componentDidMount() {
    this.scrollPane.scrollTop = 0;
    this.initialize3D();
  }

  componentDidUpdate(prevProps, prevState) {
    this.scrollToPercentage();
  }

  mainClasses() {
    if(this.props.is3D) {
      return ['use-3D'];
    } else {
      return [];
    }
  }

  getScrollListener() {
    if(this.props.is3D) {
      return (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.scrollToPercentage();
      };
    } else {
      return () => true;
    }
  }

  setMain(main) {
    this.main = main;
  }

  render() {
    return pug`
      main(
        className=this.mainClasses()
        ref=this.setMain
      )
        if this.props.is3D
          Scrollbar(
            percentage=this.props.scrollbarPercentage 
            onScrollbarMove=this.props.smoothScrollTo
          )
          ScrollMe(visible=! this.props.scrollMeSeen)
        .slope
          .scroll-pane(ref=this.setScrollPane onScroll=this.getScrollListener())
            Stand
              h1 Hi! 
              p
                | I#{ent.nbsp}am Mat and I#{ent.nbsp}develop functional and beautiful web applications in modern web technologies.
                |  I#{ent.nbsp}am also the founder of IdeaPiece Ltd. I#{ent.nbsp}am a#{ent.nbsp}full stack developer, so collecting requirements,
                |  writing user stories and specifications, software design, automated testing, development and deployment
                |  in the#{ent.nbsp}cloud is covered.
                |  Please, let me explain what my company can do for you.
              img(src='discussion.jpg' alt='Discussion about a software project')
            Stand
              h1 The server
              p
                | With respect to backend technologies, I#{ent.nbsp}have 4.5 years of experience with Ruby on Rails, JavaScript and Java.
                |  I#{ent.nbsp}specialise in Ruby on#{ent.nbsp}Rails and most of the#{ent.nbsp}projects I worked#{ent.nbsp}on
                |  used this technology (please, check my CV).
                |  This website is built with Node, Express, Gulp and Webpack.
                |  It#{ent.nbsp}uses both server side and client side rendering for React to#{ent.nbsp}enable search engines to index
                |  it#{ent.nbsp}properly.
                |  For your database needs, I#{ent.nbsp}have work experience with PostgreSQL, MySQL, Oracle (including PL/SQL) and MS#{ent.nbsp}SQL.
              img(src='ruby.jpg' alt='Ruby programming language source code')
            Stand
              h1 The cloud
              p
                | In#{ent.nbsp}IdeaPiece I#{ent.nbsp}use Linux in the cloud (e.g. this website is hosted on Amazon Web Services),
                |  DevOps technologies (e.g. puppet) and zsh.
                |  I#{ent.nbsp}prefer not to work with Windows, unless my customer's success depends on it.
                |  I#{ent.nbsp}also have some training in network administration (CCNA modules#{ent.nbsp}1 and#{ent.nbsp}2).
              img(src='data-centre.jpg' alt='A data centre')
            Stand
              h1 The browser
              p
                | With respect to frontend technologies, I#{ent.nbsp}really like modern JavaScript/ECMAScript (with or without Babel),
                |  HAML/Pug and Sass/Less/PostCSS.
                |  For#{ent.nbsp}more advanced#{ent.nbsp}UX, I can use e.g. React and Redux.
                |  I#{ent.nbsp}also know some other layout technologies, such as TeX, PostScript and XSL-FO.
                |  Unfortunately, I#{ent.nbsp}am partially dark red–dark green colourblind, so I#{ent.nbsp}will ask you to confirm whether the
                |  colours are correct before publishing.
              img(src='laptop.jpg' alt='A laptop on a desk')
            Stand
              h1 Beyond…
              p
                | I#{ent.nbsp}enjoy projects related to artificial intelligence, neural networks, machine learning, natural language processing,
                |  digital signal processing, image processing. I#{ent.nbsp}have a#{ent.nbsp}master's degree in Computer
                |  Science from #[a(href='https://www.p.lodz.pl/en') Lodz University of Technology], so I can help you
                |  to integrate AI into your projects.
                |  I can also provide advice about some less common technologies, e.g. Prolog, Lisp, Smalltalk/Seaside and others.
              img(src='markers.jpg' alt='Marker pens')
            Stand
              h1 Agile methods
              p
                | I#{ent.nbsp}usually use agile methodologies (e.g. SCRUM, Kanban) and fully manage the workflow.
                |  I#{ent.nbsp}can also manage a#{ent.nbsp}SCRUM/Kanban board (e.g. Jira, Pivotal, Yodiz, Trello)
                |  and create user stories, specifications and simple designs by collecting requirements directly
                |  from your technical or non-technical users or your clients.
                |  I#{ent.nbsp}will then convert the requirements into automatic tests 
                |  (e.g. by using Cucumber, RSpec, Capybara, Selenium), so you can be sure that all of your expectations
                |  are always fulfilled, tested and there are no surprises.
                |  UML and BPMN are also sometimes useful, if you have experience with these.
                |  All of the source code is kept on GitHub or GitLab.
              img(src='planning.jpg' alt='Whiteboard with notes')
            Stand
              h1 Managing your project
              p
                | I#{ent.nbsp}will provide estimates for each task before starting the work.
                |  I#{ent.nbsp}also manage timekeeping records, so you will always be aware about the current status of every task.
                |  To provide you with the highest level of service we can enter into a#{ent.nbsp}bespoke contract,
                |  so you are never left alone with the problems even after the contract ends.
              img(src='meeting-room.jpg' alt='A meeting room')
            Stand
              h1 Past projects I worked on
              ul
                li
                  | Automotive education and hardware control software for #[a(href='https://kgprotech.com') KG Protech]
                li
                  | Anti-fraud solution for #[a(href='https://mgage.com') mGage]
                li
                  | Data collection and processing software for #[a(href='https://cdp.net') CDP Worldwide]
                li
                  | Betting system for #[a(href='https://fitzdares.com') Fitzdares]
                li
                  | Price comparison widget for #[a(href='https://triptease.com/en/parity/') Triptease] 
                  |  (when with #[a(href='http://goodylabs.com') goodylabs])
                li
                  | Online store for #[a(href='https://prenumerata.ruch.com.pl/') Ruch] 
                  |  (when with #[a(href='http://goodylabs.com') goodylabs])
                li
                  | Human resources system for #[a(href='https://netia.pl') Netia] 
                  |  (when with #[a(href='http://uniteam.pl/') Tekten (now Uniteam)])
              img(src='whiteboard.jpg' alt='A whiteboard')
            Stand
              h1 Testimonials
              blockquote
                p
                  | It was a#{ent.nbsp}pleasure to be a#{ent.nbsp}client of IdeaPiece Ltd and work with Mat at mGage.
                  | Mat was directly responsible for developing antifraud functionality for our mobile payment platform.
                  | Mat was very professional, producing high quality code, quickly.
                  | From day#{ent.nbsp}1 it was clear that Mat understood our requirements and he was able to suggest ways
                  | to improve the initial design that we had in mind. Mat also took on tasks with no fuss,
                  | always bringing a positive attitude/approach to work.
                cite
                  a(href='https://www.linkedin.com/in/adamklug/') Adam Klug—Product Manager at mGage
              blockquote
                p
                  | ‘I#{ent.nbsp}had the pleasure of having Mat as part of my team when I#{ent.nbsp}started at Fitzdares and he was absolutely essential
                  |  in helping me get up to speed with everything from established workflows to the server architecture and internal IT.
                  |  He had really stepped up to take responsibility of a#{ent.nbsp}whole range of activities and performed all of the tasks given fantastically.
                p
                  | Mat is a#{ent.nbsp}hard worker and I#{ent.nbsp}could always rely on him for any task, he has a#{ent.nbsp}great pragmatic approach to solving technical problems 
                  |  no matter how difficult they might be. He did a#{ent.nbsp}great job managing our infrastructure with existing Puppet scripts 
                  |  as well as writing new ones as the requirement came in.’
                cite 
                  a(href='https://www.linkedin.com/in/villehellman/') Ville Hellman—CTO at Fitzdares
            Stand
              h1 Contact
              ul
                li
                  a(href='tel:+4407486424683') +44#{ent.nbsp}07486#{ent.nbsp}424683 
                li
                  a(href='mailto:mat@ideapiece.com') mat@ideapiece.com
                li
                  a(href='https://www.linkedin.com/in/mateuszgrotek') LinkedIn Mat's#{ent.nbsp}profile
                li
                  a(href='https://www.linkedin.com/company/11143433/') LinkedIn IdeaPiece's#{ent.nbsp}profile
              img(src='business-card.jpg')
            .scroll-pane-bottom-space
    `;
  }
}
