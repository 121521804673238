import React from 'react';
import Component from '../../_shared/components/component.js';

export default class Stand extends Component {
  render() {
    return pug`
      section.stand
        ${this.props.children}
    `;
  }
}
