import React from 'react';
import Component from './component.js';
import { Icon } from 'semantic-ui-react';

export default class ScrollMe extends Component {
  constructor(props) {
    super(...arguments);
    this.scrollMeClasses = this.scrollMeClasses.bind(this);
  }

  scrollMeClasses() {
    if(this.props.visible) {
      return ['scroll-me'];
    } else {
      return ['none'];
    }
  }

  render() {
    return pug`
      div(className=this.scrollMeClasses())
        Icon(fitted=true name='chevron down' size='big')
    `;
  }
}
