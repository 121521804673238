export default function (state, action) {
  if (typeof state === 'undefined') {
    return {
      is3D: false,
      previousPercentages: [0, 0, 0, 0, 0, 0],
      actualPercentage: 0,
      intendedPercentage: 0
    };
  }

  switch(action.type) {
    case 'ACTUAL SCROLL TO': {
        const percentage = Math.max(0, Math.min(100, action.actualPercentage));
        return Object.assign({}, state, {
          actualPercentage: percentage,
          previousPercentages: [...state.previousPercentages.slice(1), percentage]
        });
    }
    case 'ACTUAL SCROLL BY': {
      const percentage = Math.max(0, Math.min(100, state.actualPercentage + action.byActualPercentage));
      return Object.assign({}, state, {
        actualPercentage: percentage,
        previousPercentages: [...state.previousPercentages.slice(1), percentage]
      });
    }
    case 'INTENDED SCROLL TO':
      return Object.assign({}, state, {
        intendedPercentage: Math.max(0, Math.min(100, action.intendedPercentage))
      });
    case 'INTENDED SCROLL BY':
      return Object.assign({}, state, {
        intendedPercentage: Math.max(0, Math.min(100, state.intendedPercentage + action.byIntendedPercentage))
      });
    case 'START 3D':
      return Object.assign({}, state, {
        is3D: true
      });
  }
  
  return state;
}
