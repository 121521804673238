export default function (state, action) {
  if (typeof state === 'undefined') {
    return {
      isMenuOpen: false,
      isCookiesInfoOpen: false
    };
  }

  switch(action.type) {
    case 'TOGGLE MENU':
      return Object.assign({}, state, {
        isMenuOpen: !state.isMenuOpen
      });
    case 'OPEN COOKIES INFO':
      return Object.assign({}, state, {
        isCookiesInfoOpen: true
      });
    case 'CLOSE COOKIES INFO':
      return Object.assign({}, state, {
        isCookiesInfoOpen: false
      });
  }
  
  return state;
}
