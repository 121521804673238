export default function (state, action) {
  if (typeof state === 'undefined') {
    return {
      isBrowserWarningOpen: false,
      isFooterOpen: false,
      year: new Date().getFullYear()
    };
  }

  switch(action.type) {
    case 'OPEN BROWSER WARNING':
      return Object.assign({}, state, {
        isBrowserWarningOpen: true
      });
    case 'CLOSE BROWSER WARNING':
      return Object.assign({}, state, {
        isBrowserWarningOpen: false
      });
    case 'OPEN FOOTER':
      return Object.assign({}, state, {
        isFooterOpen: true
      });
    case 'CLOSE FOOTER':
      return Object.assign({}, state, {
        isFooterOpen: false
      });
  }
  
  return state;
}

