import React from 'react';
import Component from './component.js';
import { Icon, Modal, Button } from 'semantic-ui-react';
import ent from '../helpers/ent.js';

const ModalHeader = Modal.Header;
const ModalContent = Modal.Content;
const ModalActions = Modal.Actions;

export default class PageHeader extends Component {
  isNavigationActive(path) {
    if(this.props.location.pathname.split('/', 2)[1] === path) {
      return ['navigation-active'];
    } else {
      return [];
    }
  }

  isMenuOpen() {
    if(this.props.isMenuOpen) {
      return ['menu-open'];
    } else {
      return [];
    }
  }

  render() {
    return pug`
      header(className=this.isMenuOpen())
        nav
          ul
            li.hamburger
              a(href='#' onClick=this.props.toggleMenu)
                Icon(name='sidebar' fitted=true)
            li(className=this.isNavigationActive('')) 
              a(href='/') Home
            li(className=this.isNavigationActive('blog'))
              a(href='/posts/') Blog
            li
              a(href='/ideapiece-mat-grotek-cv-v1.pdf') CV
        .cookies-info 
          | #[a(href='#' onClick=this.props.openCookiesInfo) Cookies]
        address
          ul
            li
              a(href='https://github.com/ideapiece')
                Icon(name='github square')
              a(href='https://www.linkedin.com/in/mateuszgrotek/')
                Icon(name='linkedin square')
            li
              
              a(href='mailto:mat@ideapiece.com') 
                Icon(name='mail')
                | mat@ideapiece.com
            li
              a(href='tel:07486424683')
                Icon(name='phone')
                | 07486#{ent.nbsp}424683#{ent.nbsp}(UK)
        .ideapiece-banner
          img(src='/banner.png' alt='IdeaPiece logo')
        Modal(
          open=this.props.isCookiesInfoOpen
          closeOnDimmerClick=false
          closeOnDocumentClick=false
          size='large'
        )
          ModalHeader Cookies Policy
          ModalContent(scrolling=true)
            p
              | By using this website you agree for the#{ent.nbsp}cookies to be stored on your device.
              |  A#{ent.nbsp}cookie is a#{ent.nbsp}small file which is stored by your web browser.
              |  Specifically, this website uses temporary session cookies and
              |  permanent storage cookies.
            p
              | This website does not use any third party cookies. This website neither collects
              | nor stores personal information.
            p
              | The session cookies from this website expire automatically after 
              |  2#{ent.nbsp}hours from your last visit.
              |  They can be used, for example, to store some information you enter,
              |  so you do not need to enter the same information repeatedly on each subpage.
              |  Without them you would have to enter the same data again and again.
            p
              | The storage cookies are kept permanently on your device.
              |  They are used to remember if you have already seen some messages, pop-ups etc.,
              |  so they are not displayed repeatedly.
              |  Without them you would see the#{ent.nbsp}same information again and again.
            p
              | To learn how to disable many different types of cookies
              |  you can visit the#{ent.nbsp}website
              |  of the#{ent.nbsp}#[a(href='https://ico.org.uk/for-the-public/online/cookies/' target='_blank') Information Commissioner's Office]
              |  and apply one of the#{ent.nbsp}solutions presented there.
            p
              small Last modified 03/03/2021
          ModalActions
            Button(primary=true icon='thumbs up' content='I understand' onClick=this.props.closeCookiesInfo)
    `;
  }
}
