import { createStore, combineReducers, applyMiddleware } from 'redux';
import { apiMiddleware } from 'redux-rest-api';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import header from '../_shared/reducers/header.js';
import footer from '../_shared/reducers/footer.js';
import cookies from '../_shared/reducers/cookies.js';
import scrollbar from '../_shared/reducers/scrollbar.js';

export default function buildStore(preloadedState, history) {
  let reducer;
  let enhancer;

  if(history) {
    reducer = combineReducers({
      header: header,
      footer: footer,
      cookies: cookies,
      scrollbar: scrollbar,
      router: connectRouter(history)
    });

    enhancer = composeWithDevTools(applyMiddleware(
      routerMiddleware(history),
      apiMiddleware,
      thunk
    ));
  } else {
    reducer = combineReducers({
      header: header,
      footer: footer,
      cookies: cookies,
      scrollbar: scrollbar
    });

    enhancer = composeWithDevTools(applyMiddleware(
      apiMiddleware/*,
      thunk*/
    ));
  }

  return createStore(reducer, preloadedState, enhancer);
}
