import globalObject from 'global-object';
import actualScrollTo from './actual-scroll-to.js';
import raf from 'raf';

let previousTimestamp = 0;

export default function smoothScroll() {
  return (dispatch, getState) => {
    raf((timestamp) => {
      if(timestamp === previousTimestamp) {
        return;
      }
      previousTimestamp = timestamp;
      const threshold = 0.001;
      const state = getState();
      const actualPercentage = state.scrollbar.actualPercentage;
      const intendedPercentage = state.scrollbar.intendedPercentage;
      const previousPercentages = state.scrollbar.previousPercentages;
      if(Math.abs(actualPercentage - intendedPercentage) < threshold) {
        dispatch(actualScrollTo(intendedPercentage));
      } else {
        const nextPercentage = (
          0.5 * previousPercentages[0] + 
          0.75 * previousPercentages[0] + 
          1 * previousPercentages[0] + 
          1.25 * previousPercentages[1] + 
          1.5 * previousPercentages[2] +
          1.75 * previousPercentages[3] +
          2 * intendedPercentage
        ) / 8.75;
        dispatch(actualScrollTo(nextPercentage));
        dispatch(smoothScroll());
      }
    });
  };
}
